import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f6f9ff;
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: #000000;
  font-weight: 600;
  font-size: 38px;
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  margin-bottom: 24px;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled.div`
  font-family: poppins,sans-serif;
  background: #fff;
  color: #fff;
  box-shadow: ${({main}) => (main ? 'none' : '0 6px 20px rgba(56, 125, 255, 0.2)')};
  width: 375px;
  height: ${({main}) => (main ? '700px' : '640px')};
  text-decoration: none;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-color: ${({main}) => (main ? '#3023ae' : 'none')};
  border-style: ${({main}) => (main ? 'solid' : 'none')};
  border-width: 2px;
  padding-bottom: 48px;
  &:nth-child(2n) {
    margin-inline: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-in-out;
    transition-duration: .4s;
    transition-property: opacity,transform;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin: 24px 24px 24px 24px;
    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 24px;
  align-items: left;
  color: #fff;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: ${({main}) => (main ? '18px' : '24px')};
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  border-radius: 8px;
  max-width: fit-content;
  padding: 8px;
  color: ${({main}) => (main ? '#fff' : '#3023ae')};
  background: ${({main}) => (main ? '#3023ae' : '#fff')};
`;

export const PricingCardCost = styled.h4`
  color: #000;
  font-weight: 800;
  font-size: 58px;
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  font-size: 40px;
`;

export const PricingCardLength = styled.p`
  font-size: 18px;
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  margin-bottom: 24px;
  color: #1c2237;
`;

export const PricingCardInclusions = styled.p`
  margin-top: 24px;
  font-size: 18px;
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  margin-bottom: 24px;
  color: #000;
`;

export const PricingCardFeatures = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 18px;
  font-family: poppins,sans-serif;
  line-height: 1.1.%;
  color: #000;
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: left;
`;

export const Button = styled.button`
border-radius: 12px;
width: 100%;
background: ${({primary}) => (primary ? '#4B59F7' : '#FFFFFF')};
white-space: nowrap;
padding: 20px;
color: ${({primary}) => (primary ? '#fff' : '#000')};
font-size: 18px;
font-family: poppins,sans-serif;
font-weight: 500;
outline: none;
border: none;
cursor: pointer;
box-shadow: 0 3px 4px 0 rgba(23,23,23,.24);
display: flex;
justify-content: center;
align-items: center;

&:hover {
    transition: all 0.3s ease-out;
    scale: 1.04;
    background: #fff;
    color: #fff;
    background: ${({primary}) => (primary ? '#0467FB' : '#4B59F7')};
}

@media screen and (max-width: 960px) {
    width: 100%;
}
`;

export const BtnLink = styled(LinkS)`
display: flex;
width: 100%;
padding-inline: 24px;
justify-content: center;
align-items: center;
text-decoration: none;
`;