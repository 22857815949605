import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Routes from './routes';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes />
    </Router>
  );
}

export default App;
