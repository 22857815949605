import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 100px 0 ;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  align-items: flex-start
`;

export const TextWrapper = styled.div`
display: flex;
padding: 0 15% 20px 15%;
flex-wrap: wrap;
flex-direction: column;
align-items: flex-start;
font-size: 16px;
text-align: justify;
`;