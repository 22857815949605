import React, { useState } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { ExpandMoreRounded,ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';

import { Nav, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavItem, NavLinks, NavbarExternalLink } from './Navbar.elements'

const Navbar = ({transparency, sticky}) => {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }

    const handleClick = () => setClick(!click);

    const toggleHome = () => {
        scroll.scrollToTop();
        closeMobileMenu();
    }
    const toggleFooter = () => {
        scroll.scrollToBottom();
        closeMobileMenu();
    }

    const closeMobileMenu = () => setClick(false);

    const changeNavBackground = () => {
        window.scrollY >= 80 
        ? setNavbar(true)
        : setNavbar(false);
    }

    window.addEventListener('scroll', changeNavBackground);
    window.addEventListener('scroll', handleResize);
    window.addEventListener('resize', handleResize);

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <Nav navbar={navbar} transparency={transparency} sticky={sticky}>
                    <NavbarContainer>
                        <NavLogo to="/" onClick={closeMobileMenu}>
                            <NavIcon style={{marginRight: "10px"}}/>
                            Midas Telecom
                        </NavLogo>
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                        <NavMenu click={click}>
                            <NavItem>
                                <NavLinks to='#' onClick={toggleHome}>
                                    Home
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='pricing' smooth={true} duration={500} spy={true} exact="true" offset={-80} onClick={closeMobileMenu}>
                                    Planos
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='contact' onClick={toggleFooter}>
                                    Contato
                                </NavLinks>
                            </NavItem>
                            <NavItem expanded onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)} aria-expanded={dropdown ? "true" : "false"}>
                                { isMobile ?
                                    <Accordion style={{boxShadow: 'none', padding: '0.5rem 2rem', background: '#101522', width: '100%'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMore htmlColor='#fff'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            background: '#101522',
                                            fontFamily: 'poppins,sans-serif',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            padding: '0'
                                        }}
                                        >
                                        <Typography 
                                            style={{
                                                fontFamily: 'poppins,sans-serif',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '18px'
                                            }}
                                        >Suporte</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                background: '#101522',
                                            }}
                                        >
                                            <ul>
                                                <li key="item1" style={{padding: '5px 0'}} onClick={closeMobileMenu}>
                                                    <NavbarExternalLink target="_blank" rel="noreferrer" href="https://midasinternet.speedtestcustom.com/">Testar Conexão</NavbarExternalLink>
                                                </li>
                                                <li key="item2" style={{padding: '5px 0'}}>
                                                    <NavbarExternalLink download="Contrato_Prestação_de_Serviço.pdf" href={`${process.env.PUBLIC_URL}/Emailing Contrato Prestação de Serviços.pdf`}>Baixar Contrato</NavbarExternalLink>
                                                </li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion> 
                                    :
                                    <>
                                    <NavLinks to='contact' >
                                        Suporte
                                        <ExpandMoreRounded style={{marginLeft: 4}}/>
                                    </NavLinks>
                                    <ul 
                                        className="dropdown"
                                        style={{
                                            display: dropdown? 'block': 'none',
                                            backgroundColor: '#fafafa',
                                            padding: '20px',
                                            listStyle: 'none',
                                            borderRadius: '5px',
                                            position: 'absolute',
                                            boxShadow: '0 6px 20px rgba(56, 125, 255, 0.2)'
                                        }}
                                    >
                                        <li key="item1" style={{padding: '5px 0'}}>
                                            <NavbarExternalLink target="_blank" rel="noreferrer" href="https://midasinternet.speedtestcustom.com/">Testar Conexão</NavbarExternalLink>
                                        </li>
                                        <li key="item2" style={{padding: '5px 0'}}>
                                            <NavbarExternalLink download="Contrato_Prestação_de_Serviço.pdf" href={`${process.env.PUBLIC_URL}/Emailing Contrato Prestação de Serviços.pdf`}>Baixar Contrato</NavbarExternalLink>
                                        </li>
                                    </ul>
                                    </>
                                }
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
