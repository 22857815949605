import React from 'react';
import { HeroSection, InfoSection } from '../../components';
import Pricing from '../../components/pricing/Pricing';
import FormSection from '../../components/form/FormSection';
import { heroSection, homeObjOne, homeObjTwo } from './Data';
import { Navbar, Footer } from '../../components';
import GlobalStyle from '../../globalStyles';
import { Img } from '../homePage/HomePage.elements';
const PHONENUMBER = "55193203-7759";
const MESSAGE = "Ola! Estou interessado nos serviços da Midas";

const Home = () => {
    

    return (
        <>  
            <GlobalStyle/>
            <Navbar/>
            <HeroSection {...heroSection} />
            <Pricing />
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <FormSection />
            <Footer />
            <a href={`https://api.whatsapp.com/send?phone=${PHONENUMBER}&text=${MESSAGE}`}
                target="_new" 
                rel="noopener noreferrer">

                <Img src={require('../../assets/whatsappLogo.svg').default} alt="Text" />
            </a>
        </>
    )
}

export default Home
