import styled from 'styled-components';

export const TextLabel = styled.label`
width: 100%;
color: #000;
font-size: 18px;
font-family: poppins,sans-serif;
line-height: 1.1.%;
`;

export const SelectComp = styled.select`
width: 100%;
height: 60px;
color: #6e7791;
background-color: #fff;
font-size: 18px;
font-family: poppins,sans-serif;
box-shadow: 0 4px 10px rgba(56, 125, 255, 0.06);
padding-inline: 10px;
border: ${({invalid}) => (invalid ? '1px solid #e04e5c' : '1px solid #eff1f6')};
border-radius: 8px;
&:focus {
  outline: none !important;
  transition: all 0.3s ease-in-out;
  border: 2px solid #3023ae;
}
`;

export const TextArea = styled.textarea`
width: 100%;
height: 120px;
color: #6e7791;
font-size: 18px;
font-family: poppins,sans-serif;
box-shadow: 0 4px 10px rgba(56, 125, 255, 0.06);
padding-inline: 10px;
border: 1px solid #eff1f6;
border-radius: 8px;
&:focus {
  outline: none !important;
  transition: all 0.3s ease-in-out;
  border: 2px solid #3023ae;
}
`;