import styled, { keyframes, css } from 'styled-components';

var shake = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

var jump = keyframes`
    0% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
    50% { transform: translate(-50%, -150%) scale(1, 1); }
    100% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
`;

var slide = keyframes`
    100% { right: 2rem; }
`;

export const Img = styled.img`
position: fixed;
bottom: 2rem;
align-self: self-end; 
float: right;
right: -5rem;
cursor: pointer;
z-index: 999;
width: 55px;
height: 55px;
// animation: ${(css`${jump} 1.2s infinite`)};
// animation: ${(css`${shake} 0.5s infinite`)};
-webkit-animation: ${(css`${slide} 0.5s forwards`)};
-webkit-animation-delay: 0.4s;
animation: ${(css`${slide} 0.5s forwards`)};
animation-delay: 0.4s;
filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.2));
// filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));

@media screen and (max-width: 768px) {
    width: 45px;
    height: 45px;
}

&:hover {
    transition: all 0.3s ease-out;
    scale: 1.08;
    // animation: ${(css`${shake} 0.5s infinite`)};
    // animation-iteration-count: infinite; 
}
`;
