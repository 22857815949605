import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_API_KEY;

export const api = axios.create({
    baseURL: BASE_URL,
    headers: { "authorizationToken": `${TOKEN}`}
})

export default api;