import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Container } from '../../globalStyles';
import { toggleHome, toggleFooter } from '../../utils/nav';
import { FooterLink,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinksItems,
    FooterLinkTitle,
    FooterRoute,
    FooterExternalLink,
    FooterText,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcon,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
    FooterRow,
    FooterWrapper
} from './Footer.elements';

const PHONENUMBER = "55193203-7759";
const MESSAGE = "Ola! Estou interessado nos serviços da Midas";

const Footer = () => {

    return (
        <FooterWrapper>
            <Container>
                <FooterRow>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to="/" >
                                <SocialIcon />
                                Midas Telecom
                            </SocialLogo>
                            <FooterText>Campinas, SP</FooterText>
                            <FooterExternalLink 
                                href={`https://api.whatsapp.com/send?phone=${PHONENUMBER}&text=${MESSAGE}`}
                                target="_new" 
                                rel="noopener noreferrer"
                                >
                                    <FaWhatsapp style={{marginRight: 10, fontStyle: "bold"}}/>  (19) 3203-7759
                            </FooterExternalLink>
                            <WebsiteRights>
                                Midas Telecom ® {new Date().getFullYear()} - By 
                                <a  href="https://www.linkedin.com/in/felipe-souza-673b95173/"
                                    target="_new"
                                    style={{marginLeft: "5px", color: "#fff", fontFamily: "poppins,sans-serif"}}
                                >
                                    Felipe Souza
                                </a>
                                </WebsiteRights>
                        </SocialMediaWrap>
                    </SocialMedia>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinksItems>
                                <FooterLinkTitle>Atendimento</FooterLinkTitle>
                                <FooterText >De segunda à sexta-feira</FooterText>
                                <FooterText >Das 08:00 às 19:00</FooterText>
                                <FooterText >Sábado</FooterText>
                                <FooterText >Das 08:00 às 12:00</FooterText>
                            </FooterLinksItems>
                            <FooterLinksItems>
                                <FooterLinkTitle>Navegação</FooterLinkTitle>
                                <FooterLink onClick={toggleHome}>Início</FooterLink>
                                <FooterLink to="pricing" smooth={true} duration={500} spy={true} exact="true" offset={-80}>Planos</FooterLink>
                                <FooterLink to="info" smooth={true} duration={500} spy={true} exact="true" offset={-80}>Informações</FooterLink>
                                <FooterLink onClick={toggleFooter}>Contato</FooterLink>
                            </FooterLinksItems>
                        </FooterLinksWrapper>
                        <FooterLinksWrapper>
                            <FooterLinksItems>
                                <FooterLinkTitle>Recursos e links</FooterLinkTitle>
                                <FooterExternalLink 
                                href="https://canaltech.com.br/software/problemas-no-wifi-uma-lista-dos-mais-comuns-e-como-conserta-los/"
                                target="_new" 
                                rel="noopener noreferrer"
                                >
                                    Troubleshooting
                                </FooterExternalLink>
                                <FooterExternalLink 
                                href="https://www.techtudo.com.br/listas/2018/05/dez-dicas-para-usar-a-internet-de-forma-segura-e-evitar-hackers.ghtml"
                                target="_new" 
                                rel="noopener noreferrer"
                                >
                                    Segurança na internet
                                </FooterExternalLink>
                                <FooterRoute to="/privacy" 
                                target="_new" 
                                rel="noopener noreferrer"
                                >
                                    Política de Privacidade
                                </FooterRoute>
                                <FooterRoute to="/terms" 
                                target="_new" 
                                rel="noopener noreferrer"
                                >
                                    Termos de Uso
                                </FooterRoute>
                            </FooterLinksItems>
                            <FooterLinksItems>
                                <FooterLinkTitle>Contato</FooterLinkTitle>
                                <FooterExternalLink href="mailto:contato@midasinternet.com.br" style={{fontWeight: "bold"}}>
                                    contato@midasinternet.com.br
                                </FooterExternalLink>
                                <FooterText>Ficou em dúvida? fique à vontade para nos enviar uma mensagem.</FooterText>
                                <SocialIcons>
                                    <SocialIconLink href="/" target="_new" aria-label="Facebook">
                                        <FaFacebook />
                                    </SocialIconLink>
                                    <SocialIconLink href="https://www.instagram.com/midas_internet/" target="_new" aria-label="Instagram">
                                        <FaInstagram />
                                    </SocialIconLink>
                                </SocialIcons>
                            </FooterLinksItems>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                </FooterRow>
            </Container>
        </FooterWrapper>
    )
}

export default Footer
