export const heroSection = {
    img: require('../../assets/cables.jpg').default
}

export const homeObjOne = {
    lightBg: true,
    primary: true,
    imgStart: '',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Comece agora',
    description: 'Aproveite tudo que a internet fibra ótica pode oferecer. Além de uma conexão estável, de alta velocidade e com baixa latência, todos os nossos planos possuem download ilimitado, wifi grátis e taxa de upload igual a metade da velocidade contratada. ',
    headLine: '100% Fibra Óptica',
    lightText: false,
    topLine: 'Sobre Nossos Planos',
    img: require('../../assets/social-girl.svg').default,
    alt: 'Image',
    start: '',
    dividerColor: "#f6f9ff"
};

export const homeObjTwo = {
    lightBg: false,
    primary: true,
    imgStart: 'start',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Comece agora',
    description: 'A Midas é uma empresa jovem, inovadora que fornece serviços de telecomunicações buscando suprir a necessidade de clientes que estão cada vez mais conectados e necessitam de internet de qualidade por valores acessíveis. Somos comprometidos com a satisfação de nossos clientes.',
    headLine: 'Quem é a Midas ?',
    lightText: false,
    topLine: 'Sobre a empresa',
    img: require('../../assets/work.svg').default,
    alt: 'Image',
    start: '',
    dividerColor: "#fff"
};