import TermsOfService from './pages/terms/terms';
import Home from './pages/homePage/Home';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const Routes = () => {
    return (
        <Router>
            <Route component={Home} path="/" exact />
            <Route component={TermsOfService} path="/privacy" />
            <Route
                path='/terms'
                render={() => (
                    <TermsOfService terms={true}/>
                )}
            />
            <Route
                path='/contrato'
                render={() => {
                    window.location.href = `${process.env.PUBLIC_URL}/Emailing Contrato Prestação de Serviços.pdf`;
                    return null; // No need to render anything
                  }}
            />
        </Router>
    );
}

export default Routes;

