import React from 'react';
import { CheckCircleRounded } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardInclusions,
  PricingCardFeatures,
  PricingCardFeature,
  BtnLink,
  Button
} from './Pricing.elements';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#3023ae', size: 64 }}>
      <PricingSection id="pricing">
        <PricingWrapper>
          <PricingHeading>Nossos Planos</PricingHeading>
          <PricingContainer>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardPlan>Básico</PricingCardPlan>
                <PricingCardCost>300 Mega</PricingCardCost>
                <PricingCardLength>Ideal para quem navega nas redes sociais e trafega casualmente pela internet.</PricingCardLength>
                <Divider />
                <PricingCardInclusions>O que está incluso:</PricingCardInclusions>
                <PricingCardFeatures>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    Wifi Grátis
                  </PricingCardFeature>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    150 Mega Upload
                  </PricingCardFeature>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    Sem Consulta ao SPC/SERASA
                  </PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              <BtnLink to='contact' smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>Escolher Plano</Button>
              </BtnLink>
            </PricingCard>
            <PricingCard main>
              <div>
                <PricingCardPlan main style={{margin: "24px 0 0px 24px"}}>Mais Popular</PricingCardPlan>
                <PricingCardInfo>
                  <PricingCardPlan>Intermediário</PricingCardPlan>
                  <PricingCardCost>500 Mega</PricingCardCost>
                  <PricingCardLength>Perfeito para quem gosta de assistir séries, jogar online e necessita de mais banda para download.</PricingCardLength>
                  <Divider />
                  <PricingCardInclusions>O que está incluso:</PricingCardInclusions>
                  <PricingCardFeatures>
                    <PricingCardFeature>
                      <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                      Wifi Grátis
                    </PricingCardFeature>
                    <PricingCardFeature>
                      <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                      250 Mega Upload
                    </PricingCardFeature>
                    <PricingCardFeature>
                      <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                      Sem Consulta ao SPC/SERASA
                    </PricingCardFeature>
                  </PricingCardFeatures>
                </PricingCardInfo>
              </div>
              <BtnLink to='contact' smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>Escolher Plano</Button>
              </BtnLink>
            </PricingCard>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardPlan>Avançado</PricingCardPlan>
                <PricingCardCost>700 Mega</PricingCardCost>
                <PricingCardLength>Ideal para quem trafega grandes volumes de dados pela internet ou divide a rede como muitas pessoas.</PricingCardLength>
                <Divider />
                <PricingCardInclusions>O que está incluso:</PricingCardInclusions>
                <PricingCardFeatures>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    Wifi Grátis
                  </PricingCardFeature>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    350 Mega Upload
                  </PricingCardFeature>
                  <PricingCardFeature>
                    <CheckCircleRounded style={{marginRight: "10px"}} htmlColor="#3023ae"/>
                    Sem Consulta ao SPC/SERASA
                  </PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              <BtnLink to='contact' smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>Escolher Plano</Button>
              </BtnLink>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;