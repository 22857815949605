import React from 'react';
import { Container, Button } from '../../globalStyles'
import { InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Img,
    ImgWrapper,
    BtnLink,
} from './InfoSection.elements';

const InfoSection = ({primary, lightBg, imgStart, lightTopLine, lightTextDesc, buttonLabel, description, headLine, lightText, topLine, img, alt, start, dividerColor}) => {
    return (
        <>
            <InfoSec id='info' lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                <BtnLink to='contact'smooth={true} duration={500} spy={true} exact="true">
                                    <Button big fontBig primary={primary} style={{width: "100%"}}>
                                        {buttonLabel}
                                    </Button>
                                </BtnLink>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt}/>
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection
