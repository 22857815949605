import styled from 'styled-components';
import { ReactComponent as PersonalText } from '../../assets/personal-text.svg';

export const FormSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  align-items: flex-start;
  position: relative;
`;

export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: start;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 768px) {
    max-width:  100%;
    flex-direction: column;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}
`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled(PersonalText)`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;
`;

export const TextLabel = styled.label`
width: 100%;
color: #000;
font-weight: 800;
font-size: 18px;
font-family: poppins,sans-serif;
line-height: 1.1.%;
`;

export const TextInput = styled.input`
width: 100%;
height: 120px;
color: #6e7791;
font-size: 18px;
font-family: poppins,sans-serif;
box-shadow: 0 4px 10px rgba(56, 125, 255, 0.06);
padding-left: 10px;
border: 1px solid #eff1f6;
border-radius: 8px;
&:focus {
  outline: none !important;
  transition: all 0.3s ease-in-out;
  border: 2px solid #3023ae;
}
`;

export const Heading = styled.h1`
margin-bottom: 24px;
font-weight: 800;
font-size: 38px;
font-family: poppins,sans-serif;
line-height: 1.1.%;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '#1c2237')};

@media screen and (max-width: 768px) {
    font-size: 42px;
}
`;

export const Subtitle = styled.p`
max-width: 480px;
margin-bottom: 35px;
font-size: 18px;
font-family: poppins,sans-serif;
line-height: 24px;
text-align: justify;
color: ${({lightTextDesc}) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
`;

export const SocialIcons = styled.div`
display: flex;
justify-content: start;
align-items: start;
margin-top: 48px;
width: 240px;
`;

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
margin-right: 25px;
`;
