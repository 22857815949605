import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormSection, InfoColumn, InfoRow, Heading, Subtitle, SocialIcons, SocialIconLink } from './FormSection.elements.js';
import { Container as Wrapper, Button } from '../../globalStyles';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import axios from 'axios';
import api from '../../api/service';
import { Link } from 'react-router-dom';
import TextField from '../textInput/TextInput.js';
import Select from '../selectInput/SelectInput.js';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 6px 20px rgba(56, 125, 255, 0.2)',
    borderRadius: '18px',
    borderWidth: '2px',
    padding: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#FF914D',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



export default function SignUp() {
  const initalState = [
    {
      id: 0,
      name: 'Nome',
      value: '',
      type: 'text',
      placeholder: "Seu Nome",
      errorMessage: "",
      invalid: false,
      required: true
    },
    {
      id: 1,
      name: 'Email',
      value: '',
      type: 'text',
      placeholder: "exemplo@email.com",
      errorMessage: "",
      invalid: false,
      required: true,
      validationRegex: '[a-z0-9]+@[a-z]+\.[a-z]{2,3}'
    },
    {
      id: 2,
      name: 'Estado',
      value: '',
      type: 'select',
      errorMessage: "",
      invalid: false,
      required: true
    },
    {
      id: 3,
      name: 'Cidade',
      value: '',
      type: 'select',
      errorMessage: "",
      invalid: false,
      required: true
    },
    {
      id: 4,
      name: 'Telefone',
      value: '',
      type: 'text',
      placeholder: "99 99999-9999",
      errorMessage: "",
      invalid: false,
      required: true,
      validationRegex: '^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$'
    },
    {
      id: 5,
      name: 'Mensagem',
      value: '',
      type: 'text',
      placeholder: "O que tem em mente?",
      errorMessage: "",
      invalid: false,
      required: false
    }
  ];

  const classes = useStyles();
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [inputArray, setInputArray] = useState(initalState);

  async function sendEmail() {
    const name = inputArray[0].value;
    const email = inputArray[1].value;
    const uf = inputArray[2].value;
    const city = inputArray[3].value;
    const phoneNumber = inputArray[4].value;
    const message = inputArray[5].value;

    const data = {
      name,
      email,
      uf,
      city,
      phoneNumber,
      message
    }

    console.log(data);
    await api.post('/emailhandler', data)
      .then(response => {
        alert('Enviado com sucesso! Entraremos em contato em breve.');
        console.log(response);
      })
      .catch(error => console.log(error))
      .finally(() => setInputArray(initalState))
  }

  function handleValidation(e) {
    e.preventDefault();

    const newArray = inputArray.slice();
    newArray.forEach(function(item) {
      if (item.required) {
        let regex = new RegExp(item?.validationRegex);
        if (item.value == null || item.value.trim() === '') {
          item.invalid = true;
        }
        else if (!regex.test(item.value)) {
          item.invalid = true;
          item.errorMessage = `Insira um ${item.name} válido`;
        }
        else {
          item.invalid = false;
          item.errorMessage = '';
        }
      }
      return item;
    });
    setInputArray(newArray);

    if (newArray.find(el => el.invalid === true) === undefined) sendEmail(e);
  }

  function handleChange(event) {
    if (!(event.target.value == null || event.target.value.trim() === '')) {
      const newArray = inputArray.slice();
      newArray[event.target.id].invalid = false;
      setInputArray(newArray);
    }
    const newArray = inputArray.slice();

    newArray[event.target.id].value = event.target.value;
    setInputArray(newArray);
  }

  useEffect(() => {
    axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {
      const uf = response.data.map(uf => uf.sigla);
      setUfs(uf);
    });
  }, []);

  useEffect(() => {
    if (inputArray[2].value === '') {
      return;
    }

    axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${inputArray[2].value}/municipios`)
      .then(response => {
        const cities = response.data.map(city => city.nome);
        setCities(cities);
      });
  }, [inputArray]);

  return (
    <FormSection id="contact">
      <Wrapper>
        <InfoRow>
          <InfoColumn style={{paddingTop: "130px"}}>
              <div style={{height: "6px", backgroundColor: "#3023ae", width: "120px", marginBottom: "10px"}}/>
              <Heading lightText={false}>Entre em contato conosco através das nossas redes sociais ou pelo formulário a seguir</Heading>
              <Subtitle lightTextDesc={false}> 
                Ficou em dúvida ou quer saber mais sobre nossos planos? entre em contato e responderemos assim que possível
              </Subtitle>
            <SocialIcons>
              <SocialIconLink href="/" target="_new" aria-label="Facebook">
                  <FaFacebook color='#000'/>
              </SocialIconLink>
              <SocialIconLink href="https://www.instagram.com/midas_internet/" target="_new" aria-label="Instagram">
                  <FaInstagram color='#000'/>
              </SocialIconLink>
            </SocialIcons>
          </InfoColumn>
          <InfoColumn>
            <Container component="main" maxWidth="md">
              <CssBaseline />
              <div className={classes.paper}>
                <form className={classes.form} onSubmit={handleValidation} id="contactForm">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id={inputArray[0].id}
                        title={inputArray[0].name}
                        value={inputArray[0].value}
                        onChange={handleChange}
                        placeholder={inputArray[0].placeholder}
                        required
                        invalid={inputArray[0].invalid}
                        errorMessage={inputArray[0].errorMessage}
                        type={inputArray[0].type}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={inputArray[1].id}
                        title={inputArray[1].name}
                        value={inputArray[1].value}
                        onChange={handleChange}
                        placeholder={inputArray[1].placeholder}
                        required
                        invalid={inputArray[1].invalid}
                        errorMessage={inputArray[1].errorMessage}
                        type={inputArray[1].type}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                          id={inputArray[2].id}
                          title={inputArray[2].name}
                          native
                          value={inputArray[2].value}
                          onChange={handleChange}
                          label={inputArray[2].name}
                          invalid={inputArray[2].invalid}
                          inputProps={{
                            name: 'state',
                            id: 'state',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {ufs.map(uf => (
                            <option key={uf} value={uf}>{uf}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                          id={inputArray[3].id}
                          title={inputArray[3].name}
                          native
                          value={inputArray[3].value}
                          onChange={handleChange}
                          label={inputArray[3].name}
                          invalid={inputArray[3].invalid}
                          inputProps={{
                            name: 'city',
                            id: 'city',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {cities.map(city => (
                            <option key={city} value={city}>{city}</option>
                          ))}
                        </Select>

                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={inputArray[4].id}
                        title={inputArray[4].name}
                        value={inputArray[4].value}
                        onChange={handleChange}
                        placeholder={inputArray[4].placeholder}
                        required
                        invalid={inputArray[4].invalid}
                        errorMessage={inputArray[4].errorMessage}
                        type={inputArray[4].type}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        id={inputArray[5].id}
                        title={inputArray[5].name}
                        value={inputArray[5].value}
                        onChange={handleChange}
                        placeholder={inputArray[5].placeholder}
                        invalid={inputArray[5].invalid}
                        errorMessage={inputArray[5].errorMessage}
                        type={inputArray[5].type}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                      <FormControlLabel
                          control={<Checkbox value="allowExtraEmails" color="primary" required/>}
                          label={`Ao informar meus dados, concordo com a`}
                          style={{fontFamily: "poppins,sans-serif"}}
                      />
                      <Link target="_blank" to='/privacy' style={{textDecoration: 'none', fontFamily: "poppins,sans-serif"}}>Política de privacidade.</Link>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    big fontBig primary={true} style={{width: "100%"}}
                  >
                    Enviar
                </Button>
                </form>
              </div>
            </Container>
          </InfoColumn>
        </InfoRow>
      </Wrapper>
    </FormSection>
  );
}