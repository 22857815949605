import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { ReactComponent as Logo } from '../../assets/logo.svg';

export const FooterWrapper = styled.div`
background-color: #091530;
padding: 6% 0 6% 0;
position: relative;
`;

export const FooterRow = styled.div`
display: flex;
// margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: start;
justify-content: space-between;
flex-direction: row;

@media screen and (max-width: 820px) {
    flex-direction: column;
}
`;

export const FooterSubscription = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-bottom: 24px;
padding: 24px;
color: #fff;
`;

export const FooterSubheading = styled.p`
font-family: poppins,sans-serif;
margin-bottom: 24px;
font-size: 24px;
`;

export const FooterSubText = styled.p`
margin-bottom: 24px;
font-size: 20px;
`;

export const Form = styled.div`
display: flex;
justify-content: center;
align-items: center;

@media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
}
`;

export const FormInput = styled.input`
padding: 10px 20px;
border-radius: 2px;
margin-right: 10px;
outline: none;
border: none;
font-size: 16px;
border: 1px solid #fff;

&::placeholder { 
    color: #242424;
}

@media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
}
`;

export const FooterLinksContainer = styled.div`
width: fit-content;
display: flex;
justify-content: center;
overflow-x: hidden;

@media screen and (max-width: 820px) {
    padding-top: 32px;
    flex-direction: column;
}
@media screen and (max-width: 320px) {
    flex-direction: column;
    align-items: center;
}
`;

export const FooterLinksWrapper = styled.div`
display: flex;

@media screen and (max-width: 820px) {
    flex-direction: column;
}
`;

export const FooterLinksItems = styled.div`
display: flex;
flex-direction: column;
margin: 0 16px;
text-align: left;
width: fit-content;
box-sizing: border-box;
max-width: 250px;
color: #fff;

@media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
}
`;

export const FooterLinkTitle = styled.h2`
margin-bottom: 16px;
font-family: poppins,sans-serif;
`;

export const FooterLink = styled(LinkS)`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-family: poppins,sans-serif;
cursor: pointer;

&:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
}
`;

export const FooterRoute = styled(Link)`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-family: poppins,sans-serif;
cursor: pointer;

&:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
}
`;

export const FooterExternalLink = styled.a`
color: #fff;
text-decoration: none;
font-family: poppins,sans-serif;
margin-bottom: 0.5rem;
cursor: pointer;

&:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
}
`;

export const FooterText = styled.p`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-family: poppins,sans-serif;
text-wrap: break-spaces;
`;

export const SocialMedia = styled.section`
max-width: 1000px;
width: fit-content;
`;

export const SocialMediaWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
max-width: 1000px;
// margin: 40px auto 0 auto;

@media screen and (max-width: 820px) {
    flex-direction: column;
}
`;

export const SocialLogo = styled(Link)`
color: #fff;
justify-self: start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
font-family: poppins,sans-serif;
font-weight: 800;
font-size: 28px;
display: flex;
align-items: center;
margin-bottom: 16px;
`;

export const SocialIcon = styled(Logo)`
width: 25px;
height: 25px;
margin-right: 10px;
`;

export const WebsiteRights = styled.small`
color: #fff;
margin-top: 68px;
font-family: poppins,sans-serif;
`;

export const SocialIcons = styled.div`
display: flex;
justify-content: start;
align-items: start;
margin-top: 48px;
width: 240px;
`;

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
margin-right: 25px;
`;
