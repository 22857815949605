import styled from 'styled-components';
import { Container } from '../../globalStyles';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as LinkS } from 'react-scroll';
import { ReactComponent as Logo} from '../../assets/logo.svg';

export const Nav = styled.nav`
    background: ${props => {
            if(props.transparency) {
                return "linear-gradient(95deg,#3023ae 40%,#c86dd7 100%)!important"
            } else if(props.navbar) {
            return "linear-gradient(95deg,#3023ae 40%,#c86dd7 100%)!important"
            } else return "linear-gradient(95deg,#3023ae 40%,#c86dd7 100%)!important"
        }
    };
    height: 80px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: ${props => {
            if(props.sticky) {
                return "sticky"
            } else if(props.navbar) {
            return "fixed"
            } else return "absolute"
        }
    };
    top: 0;
    z-index: 999;
    transition: all 0.5s ease-out;
    min-width: 100%;
`;

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 80px;
    /* margin: 'auto';
    padding-left: 0;
    margin-left: 12%; */
    ${Container}
`;

export const NavLogo = styled(Link)`
color: #fff;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-weight: 800;
display: flex;
align-items: center;
font-family: poppins,sans-serif;
font-size: 38px;

@media only screen and (max-width: 420px) {
    font-size: 24px;
}
`;

export const NavIcon = styled(Logo)`
/* margin: 0.5rem; */
width: 35px;
height: 45px;
`;

export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;

@media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({click}) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #101522;
    align-items: start;
}
`;

export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
font-family: poppins,sans-serif;
font-weight: 500;
align-items: center;

&:hover {
    border-bottom: ${({expanded}) => (expanded ? '2px solid transparent' : '2px solid #4b59f7')};
}

@media screen and (max-width: 960px) {
    width: 100%;
    align-items: start;

    &:hover {
        border: none;
    }
}
`;

export const NavbarExternalLink = styled.a`
text-decoration: none;
font-family: poppins,sans-serif;
padding-bottom: 8px;
cursor: pointer;
color: #6e7791;

&:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
}
`;

export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;
cursor: pointer;
font-family: poppins,sans-serif;
font-size: 18px;
font-weight: bold;

@media screen and (max-width: 960px) {
    text-align: start;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
        color: #4b59f7;
        transition: all 0.3s ease;
    }
}
`;

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}
`;

export const NavBtnLink = styled(LinkS)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 8px 16px;
height: 100%;
width: 100%;
border: none;
outline: none;
`;