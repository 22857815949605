import React from 'react';
import { ArrowForwardRounded } from '@material-ui/icons';
import { Heading, 
         Subtitle, 
         TextWrapper,
         BtnLink,
         Container,
         InfoRow,
         InfoColumn,
         ImgWrapper,
         Img,
         TopLine,
         Button,
         FlexContainer,
         InfoSec
} from '../heroSection/HeroSection.elements';

const HeroSection = () => {
    return (
            <InfoSec>
                <Container>
                    <InfoRow imgStart={false}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={false}>Internet Fibra Ótica</TopLine>
                                <Heading lightText={false}>Internet de qualidade por um preço justo</Heading>
                                <Subtitle lightTextDesc={false}> 
                                    Internet fibra ótica para todos os tipos de usuários, conheça nossos planos e descubra qual é o melhor para você
                                </Subtitle>
                                <FlexContainer>
                                    <BtnLink to='contact'smooth={true} duration={500} spy={true} exact="true">
                                        <Button big fontBig primary={true}>
                                            Escolher Plano
                                            <ArrowForwardRounded style={{marginLeft: 4}}/>
                                        </Button>
                                    </BtnLink>
                                    <BtnLink to='info'smooth={true} duration={500} spy={true} exact="true">
                                        <Button big fontBig primary={false}>
                                            Saber Mais
                                        </Button>
                                    </BtnLink>
                                </FlexContainer>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={false}>
                                <Img src={require('../../assets/Smart-appliances.svg').default} alt="Text"/>
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
    )
}

export default HeroSection
