import React, {  } from 'react';
import { SelectComp, TextLabel } from './SelectInput.elements';




const Select = (props) => {
  return (
    <TextLabel>
      {props.title} {props.invalid && <TextLabel style={{color: "#e04e5c"}}>*</TextLabel>}
      <SelectComp id={props.id} {...props} invalid={props.invalid}/>
    </TextLabel>
  );
}

export default Select;